
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Tag
} from "antd";

import ParticipantModal from "../components/modals/participant"
import {useEffect, useState} from 'react';
import axios from 'axios';
import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
import face2 from "../assets/images/face-2.jpg";
const { Title } = Typography;

// table code start
const columns = [
  {
    title: "PARTICIPANT",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "NDIS #",
    dataIndex: "ndisnumber",
    key: "ndisnumber",
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    key: "address",
  },

  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
    width: "7%",
  },

  {
    title: "ACTION",
    key: "action",
    dataIndex: "action",
    width: "7%",
  }
];
function ParticipantsTable({permissionsprop}) {
  const [openParticipantModal, setOpenParticipantModal] = useState(false)
  const [participants, setParticipants] = useState([])
  const [participant, setParticipant] = useState(null)
  const [action, setAction] = useState('create')
  const [permissions, setPermissions] = useState([]);

  useEffect(()=>{
    setPermissions(permissionsprop);
  })


  useEffect(() => {
    refresh();
  }, [permissions]);

  const refresh = () =>{
    axios.get(process.env.REACT_APP_API_URI+"/participants/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      var arrayList = [];
      data.data.res.forEach(element => {
        arrayList.push(
          {
            key: "1",
            name: (
              <>
                <Avatar.Group>
                  <div className="avatar-info">
                    <Title level={5}>{element.name}</Title>
                    <p><small><MailTwoTone /> {element.email}</small> <small><PhoneTwoTone /> {element.phone}</small> </p>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            ndisnumber: (
              <>
                <div className="author-info">
                  <p> {element.ndisnumber}</p>
                </div>
              </>
            ),
            address: (
              <>
                <div className="author-info">
                  <p> {element.address}</p>
                </div>
              </>
            ),
            status: (
              <>
                {
                  true?
                  <Tag color={"green"} key={`Active`}>
                    {`Active`.toUpperCase()}
                  </Tag>
                  :
                  <Tag color={"red"} key={`Active`}>
                    {`Active`.toUpperCase()}
                  </Tag>
                }
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "Update_Participant")?
                  <Button onClick={()=>{setAction('edit'); setParticipant(element); setOpenParticipantModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined />Edit</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "Delete_Participant")?
                  <Button onClick={()=>{deleteParticipant(element.id)}} type=""  className="ant-btn-sm m-5" danger>Delete</Button>
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      setParticipants(arrayList);
    }).catch(error =>{
      console.log(error)
    })
  }

  const deleteParticipant = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/participants/?id=${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      console.log(error) 
    })
  } 

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="PARTICIPANTS"
              extra={
                <>
                  <Row>
                    <Col>
                      <Input placeholder="Seach Participant" />
                    </Col>
                    <Col>
                      <Button onClick={()=>{setOpenParticipantModal(true); setAction`create`}}><small>ADD PARTICIPANT</small></Button>
                    </Col>
                    </Row>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={participants}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <ParticipantModal participant={participant} action = {action} refresh ={refresh} setOpenParticipantModal = {setOpenParticipantModal} show = {openParticipantModal}/>
    </>
  );
}

export default ParticipantsTable;
