import { Card } from "antd";


const RegistrationSuccess = () => {
    return <>
        <div className="d-flex justify-content-center" style={{width : "100%"}}>
            <Card style={{width : "fit-content", color : "green", marginTop : "10%", textAlign : "center"}}>
                <div style={{fontSize : 20}}>Congratulations, your have successfully submitted </div>
                <div style={{marginTop :"5%"}}>Check our email to complete your registration process</div>
            </Card>
        </div>
    </>
}

export default RegistrationSuccess;