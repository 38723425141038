import { useEffect, useState } from "react";
import Incidents from "../components/tables/MyIncidents"
import axios from "axios"

function IncidentsTable({permissionsprop}) {
  const [permissions, setPermissions] = useState([]);

  useEffect(()=>{
    setPermissions(permissionsprop)
  })
  
  return (
    <>
     <Incidents permissionsprop = {permissions} refresh={()=>{}} view={`dashboard`}/>
    </>
  );
}

export default IncidentsTable;
