
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Input,
    Tag
  } from "antd";
  
  import {useEffect, useState} from 'react';
  import axios from 'axios';
  import moment from 'moment';
  import { PhoneTwoTone, MailTwoTone, EditOutlined, EyeTwoTone, CloudDownloadOutlined, DeleteTwoTone, DownloadOutlined } from "@ant-design/icons";
  import face2 from "../../assets/images/face-2.jpg";
  import IncidentModal from "../../components/modals/incident"
  import Incident from "../../components/modals/ViewIncident"
import DatePickerComponent from "../date/period";

  const { Title } = Typography;
  
  // table code start
  var columns =[]
  var columns_data = [
    {
      title: "REPORTER",
      dataIndex: "reporter",
      key: "reporter",
      width: "20%",
    },
    {
      title: "REPORTED TO",
      dataIndex: "reportedto",
      key: "reportedto",
      width: "20%",
    },
    {
      title: "PARTICIPANT",
      dataIndex: "participant",
      key: "participant",
      width: "20%",
    },
    {
      title: "WHAT HAPPENED DURING THE INCIDENT",
      dataIndex: "descriptionDuring",
      key: "descriptionDuring",
    },
  
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      width: "4%",
    },
  
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function IncidentsTable({view, incident_data, refresh, permissionsprop}) {
    const [openIncidentModal, setOpenIncidentModal] = useState(false)
    const [openIncident, setOpenIncident] = useState(false)
  
    const [employees, setEmployees] = useState([])
    const [incidents, setIncidents] = useState([])
    const [selectedIncident, setSelectedIncident] = useState({})
    const [action, setAction] = useState(`create`)
    const [permissions, setPermissions] = useState([])
    const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])


    useEffect(()=>{
      setPermissions(permissionsprop);
    });
    
    useEffect(() => {
        getIncidents();
      }, [permissions, incident_data, reportPeriod]);
    
    const getIncidents = () =>{

          if(view == `profile`){
              columns = columns_data.filter(x => x.title != 'REPORTER')
              axios.get(process.env.REACT_APP_API_URI+`/incidents/my?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
                setIncidents(data.data.res);
                formatTableData(data.data.res);
              }).catch(error=>{
                console.log(error)
              })
          }

          if(view == `dashboard` ){
              columns = columns_data;
              axios.get(process.env.REACT_APP_API_URI+`/incidents?startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
              setIncidents(data.data.res);
              formatTableData(data.data.res);
          }).catch(error =>{
              console.log(error)
          })
        }
      
    }



    const formatTableData = (data) => {
      var arrayList = [];
            data.forEach(element => {
            arrayList.push(
                {
                key: "1",
                reporter: (
                    <>
                    <Avatar.Group>
                        <Avatar
                        className="shape-avatar"
                        shape="square"
                        size={40}
                        src={element?.creator?.profilepicture}
                        ></Avatar>
                        <div className="avatar-info">
                        <Title level={5}>{element.creator?.name}</Title>
                        <p><small><a type="email" href = {`mailto:${element.creator?.email}`}><MailTwoTone />Send email</a></small> <small><a type="tel" href={`tel:${element.creator?.phone}`}><PhoneTwoTone />Dial number</a></small></p>
                        </div>
                    </Avatar.Group>{" "}
                    </>
                ),
                
                reportedto : (
                    <>
                    <div className="author-info">
                    <Title level={5}>{element.reportedTo?.name}</Title>
                        <p><small><a href={`mailto:${element.creator?.email}`}><MailTwoTone /> Send email</a> </small> <small><a href={`tel:${element.creator?.phone}`}><PhoneTwoTone /> Dial number</a></small></p>
                    </div>
                    </>
                ),

                participant : (
                    <>
                    <div className="author-info">
                    <Title level={5}>{element.participant?.name}</Title>
                        <p><small><a href={`mailto:${element.participant?.email}`}><MailTwoTone /> Send email</a> </small> <small><a href={`tel:${element.participant?.phone}`}><PhoneTwoTone /> Dial number</a></small></p>
                    </div>
                    </>
                ),

                descriptionDuring : (
                    <>
                    <div className="author-info">
                    <small> Incident Date: {`${`${moment(element.date).format('ddd')}, ${moment(element.date).format('DD')}, ${moment(element.date).format('MMM')}`} ${element.time}`}</small> 
                        <div className="discription-during"><p>{element.description_during}</p></div>
                    </div>
                    </>
                ),
                status: (
                    <>
                    {
                        true?
                        <Tag color={"green"} key={`Active`}>
                        {`Active`.toUpperCase()}
                        </Tag>
                        :
                        <Tag color={"red"} key={`Active`}>
                        {`Active`.toUpperCase()}
                        </Tag>
                    }
                    </>
                ),
                action: (
                    <>
                    <a href = {process.env.REACT_APP_API_URI+`/incidents/pdf/${element.id}`}>
                      <Button title={`Download PDF`} 
                        className="ant-btn-sm m-5"
                      ><DownloadOutlined/></Button>
                    </a>

                    <Button title={`Open Incident`} 
                        className="ant-btn-sm m-5"
                        onClick={()=>{
                        setSelectedIncident(element);
                        setOpenIncident(true);  
                        }}
                    ><EyeTwoTone /></Button>
                    {
                      permissions.some(x=>x.permission == 'Update_Incident')?
                      <Button 
                      className="ant-btn-sm m-5"
                      title="Edit Incident" type=""
                      onClick = {(event)=>{
                          const data = incidents.filter(x => x.id = element.id)[0];
                          setSelectedIncident(element);
                          setAction('edit')
                          setOpenIncidentModal(true);               
                      }}
                      ><EditOutlined /> 
                      </Button>
                      :
                      ""
                    }
                    
                    {
                      permissions.some(x=>x.permission == 'Delete_Incident')?
                      <Button onClick={()=>{deleteIncident(element.id)}} value={element.id} className="ant-btn-sm" danger title="Delete Incident"><DeleteTwoTone  twoToneColor="#eb2f96"/></Button> 
                      :
                      ""
                    }
    
                    </>
                ),
                }
            )
            });
            setEmployees(arrayList);
    }
  
    const deleteIncident = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/incidents/?id=${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
        getIncidents();
        refresh();
      }).catch(error =>{
        console.log(error) 
      })
    }
  
    const search = (value) =>{
      const filteredData = incidents.filter(x =>
          {
            return x?.creator?.name?.toUpperCase().includes(value.toUpperCase())||
             x?.participant?.name?.toUpperCase().includes(value.toUpperCase())||
             x?.location?.toUpperCase().includes(value.toUpperCase()) ||
             x?.description_after?.toUpperCase().includes(value.toUpperCase()) ||
             x?.description_during?.toUpperCase().includes(value.toUpperCase()) ||
             x?.description_before?.toUpperCase().includes(value.toUpperCase()) ||
             x?.location?.toUpperCase().includes(value.toUpperCase())
          }
        );
      formatTableData(filteredData);
    }
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="INCIDENTS"
                extra={
                  <>
                    <Row>
                      <Col>
                        <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/> 
                      </Col>
                      <Col>
                        <Input onChange={(event)=> search(event.target.value)} placeholder="Search Incident" />
                      </Col>
                      <Col>
                        <Button onClick={()=>{setOpenIncidentModal(true); setAction('create')}} > CREATE INCIDENT</Button>
                      </Col>

                      <Col>
                        <a  href={process.env.REACT_APP_API_URI+'/incidents/excel?token='+localStorage.getItem("token")+`&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`} >
                          <Button> EXPORT EXCEL</Button>
                        </a>
                      </Col>

                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <IncidentModal 
        action = {action}
        refresh = {()=>{getIncidents(); refresh();}} setOpenIncidentModal = {setOpenIncidentModal} 
        show = {openIncidentModal} 
        incident = {selectedIncident}
        data = {action== `create` || action == `opening`? null: selectedIncident.id}/>
        <Incident show = {openIncident} modalFunction ={setOpenIncident}  data = {selectedIncident}/>
      </>
    );
  }
  
  export default IncidentsTable;
  