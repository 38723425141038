import { Button, Modal, Form, Input, Select, Row, Col, DatePicker, TimePicker, List, Card, Descriptions } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import moment from 'moment';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import CorrectiveActionModal from "./corrective_action";

const { TextArea } = Input;

const App = ({show, modalFunction, data }) => {
    const [openCorrectiveActionModal, setOpenCorrectiveActionModal] = useState(false)
    const [action_on_form_2, setActionOnFormTwo] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const { Option } = Select;
  const [permissions, setPermissions] = useState([])
  const [name, setName] = useState("")
  const [permission_ids, setPermissionIds] = useState([])


  const [loading, setLoading] = useState(false)

  return (
    <>
      <Modal 
      title={`INCIDENT NUMBER.  [${data?.id}]`}
      width={window.innerWidth<950? `100%` : `${0.6*window.innerWidth}px`}
      visible={isModalVisible} 
      onOk={()=>modalFunction(false)} 
      onCancel={()=>modalFunction(false)}
      footer={[
        <Button key="submit"
        style={{fontSize : "100%"}}
        onClick={()=>{
            var content = document.getElementById("incident");
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
        }}>
            <PrinterOutlined/>Print
        </Button>,
        <Button key="submit" style={{fontSize : "100%"}} href = {process.env.REACT_APP_API_URI+`/incidents/pdf/${data.id}`} >
           <DownloadOutlined/> Download Incident
        </Button>,
        <Button key="submit"  style={{fontSize : "100%"}} type="primary" loading={loading} onClick={()=>{setActionOnFormTwo(`create`); setOpenCorrectiveActionModal(true)}}>
            Create a Corrective Action
        </Button>
      ]}
      >

        <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: "absolute"}}></iframe>

        <Card id ="incident" style={{textAlign: "center"}}>
           <div>
                <div>
                    <div style={{ fontSize : "150%"}}> {data?.participant?.name} [{data?.participant?.ndisnumber}]</div>
                </div>
                <div>
                    <div style={{fontFamily : "fantasy", fontStyle:"italic"}}>{data?.participant?.address}, {data?.participant?.phone}, {data?.participant?.email}</div>
                </div>
           </div>

           <div style={{marginTop :"2%"}}>
                <div>Reported By: <span style={{fontFamily : "fantasy", fontStyle:"italic"}}>{data?.creator?.name}</span></div>
                <div>Reported To: <span style={{fontFamily : "fantasy", fontStyle:"italic"}}>{data?.reportedTo?.name}</span></div>
                <div>Dated: <span style={{fontFamily : "fantasy", fontStyle:"italic"}}>{moment(data?.date).format("ddd DD MMM YYYY")} AT <span style={{fontFamily : "fantasy", fontStyle:"italic"}}> {data?.time} </span></span></div>
                <div>Incident Location: <span style={{fontFamily : "fantasy", fontStyle:"italic"}}>{data?.location}</span></div>
                <div>Incident Category: {
                        (data?.incidentCategoryAndAssessments || []).map(item => <>
                            <span style={{fontFamily : "fantasy", fontStyle:"italic"}}>{item?.category?.name} {`[${item?.assessment?.name}]`}</span>
                            <br></br>
                        </>)
                    } </div>
           </div>

           <div style={{marginTop:"2%"}}>
                <div style={{fontSize:"130%", fontStyle:"italic"}}>What happened before the Incident?</div>
                <Card style={data?.description_before? {textAlign : "justify"} : {}}><div dangerouslySetInnerHTML={{__html:data?.description_before? data?.description_before : "No incident description provided..."}}></div></Card>
           </div>

           <div style={{marginTop:"2%"}}>
                <div style={{fontSize:"130%", fontStyle:"italic"}}>What happened during the Incident?</div>
                <Card style={data?.description_during ? {textAlign : "justify"} : {}}>{data?.description_during? data?.description_during : "No incident description provided..."}</Card>
           </div>

           <div style={{marginTop:"2%"}}>
                <div style={{fontSize:"130%", fontStyle:"italic"}}>What happened after the Incident?</div>
                <Card style={data?.description_after ? {textAlign : "justify"} : {}}>{data?.description_after? data?.description_after :  "No incident description provided..."}</Card>
           </div>
           
           <div style={{marginTop:"2%"}}>
                <div style={{fontSize:"130%", fontStyle:"italic"}}>Incident Witnesses </div>
                {
                    (data?.witnesses || []).map(witness =><Card style={data?.description_after ? {textAlign : "justify"} : {}}>
                        <div><span style={{fontWeight : "bold"}}>Name:</span> {witness?.name} <span style={{fontWeight : "bold"}}>Phone Number:</span> {witness?.phone} <span style={{fontWeight : "bold"}}>Email:</span> {witness?.email} </div>
                    </Card>)
                }           
                </div>

           <div style={{marginTop:"2%"}}>
                <div style={{fontSize:"130%", fontStyle:"italic"}}>Here are incident supporting documents</div>
                {
                    (data?.documents || []).map(document =><Card style={data?.description_after ? {textAlign : "justify"} : {}}>
                        <div>{document?.name} <Button style={{float:"right", fontSize : "110%"}} href={document?.url} target='blank'><DownloadOutlined/>Download</Button> </div>
                    </Card>)
                }
                
           </div>
        </Card>
        
      </Modal>
      <CorrectiveActionModal 
            action_value = {action_on_form_2}
            setOpenCorrectiveActionModal = {setOpenCorrectiveActionModal} 
            show = {openCorrectiveActionModal} 
            incidentId = {data?.id}
            refresh = {()=>{}}
          />
    </>
  );
};

export default App;