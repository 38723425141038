
import { useState, useEffect } from "react";
import axios from "axios"

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import MyIncidents from '../components/tables/MyIncidents'
import PictureUpload from '../components/modals/pictureupload';
import IncidentModal from "../components/modals/incident"



const pencil = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
      className="fill-gray-7"
    ></path>
    <path
      d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
      className="fill-gray-7"
    ></path>
  </svg>,
];




function Profile({permissionsprop}) {
  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const [openIncidentModal, setOpenIncidentModal] = useState(false)

  const [action, setAction] = useState(`create`)

  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)


  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () =>{
    axios.get(process.env.REACT_APP_API_URI+"/employees/profile", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setProfile(data.data.res);
      setIncidents(data.data.res.incidents)
    }).catch(error =>{
      console.log(error)
    })
  }

   const [requestMessage, setReqMessage] = useState("");

  const requestPasswordResetLink = () =>{
    axios.get(process.env.REACT_APP_API_URI+"/auth/resetlink", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setReqMessage(data.data.res);
      message.success(data.data.res)
    }).catch(error =>{
      if (error.response) {
        // Server Error Massage
        console.log(error.response.data?.res);
        message.error(error.response.data?.res)
        setReqMessage( error.response.data?.res);
      } else if (error.request) {
        // Request was made but no response
        // this.setState({errorMessage : error.request})
        message.error(error.request)
        console.log(error.request);
      } else {
        // Network Error
        console.log('Error', error.message);
        message.error(error.message)
        // this.setState({errorMessage : error.message})
      }
    })
  }

  const [profile, setProfile] = useState({})
  const [incidents, setIncidents] = useState([])

  const markAsRead = (id) =>{
    axios.get(process.env.REACT_APP_API_URI+`/notifications/seen/${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
      message.success(data.data.res)
    })
  }

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <div onClick={()=>setOpenPictureUploadModal(true)}>
                  <Avatar size={74} shape="square" src={profile?.profilepicture} />
                </div>
                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{profile?.name}</h4>
                  <p>{profile?.access_level?.name}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group>
                <Radio.Button onClick={()=>requestPasswordResetLink()}>Reset Password</Radio.Button>
              </Radio.Group>
              <Radio.Group>
                <Radio.Button onClick={()=>setOpenIncidentModal(true)}>Create Incident</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={10} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            extra={<Button type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <hr/>
            <Descriptions title={`Account Information`}>
              <Descriptions.Item label="Full Name" span={3}>
                {profile?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                {profile?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {profile?.email}
              </Descriptions.Item>
              <Descriptions.Item label="AccessLevel" span={3}>
                {profile?.access_level?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {profile?.address}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24} md={14} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Account Notifications</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={profile?.notifications}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item actions={[<Button onClick={()=>markAsRead(item?.id)} type="link">Make as read</Button>]}>
                  <List.Item.Meta
                    avatar={
                      <Avatar shape="square" size={48} src={item?.creator?.profilepicture} />
                    }
                    title={item?.creator?.name}
                    description={item.notification}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <>
            <h6 className="font-semibold">My Incidents</h6>
            <p>A list of your Incidents you have recorded</p>
          </>
        }
      >
          <MyIncidents permissionsprop={permissionsprop} refresh={()=>refresh()} incident_data = {incidents} view={`profile`}/>
      </Card>
      <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={profile?.id}/>

      <IncidentModal 
        action = {action}
        refresh = {()=>{refresh()}} setOpenIncidentModal = {setOpenIncidentModal} 
        show = {openIncidentModal} 
        data = {null}/>
    </>
  );
}

export default Profile;
