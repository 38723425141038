import {CardElement, CardExpiryElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Button, Card, Form, message } from 'antd';
import '../assets/styles/StripeCheckout.css';
import axios from 'axios';
import { useEffect, useState } from 'react';

const StripeCard = () =>{
    let elements = useElements();
    const stripe = useStripe();
    const [state, setState] = useState();
    
  
    const handleSubmit =  async (e) => {
        e.preventDefault();
        setState({...state, loading : true});
        stripe?.createPaymentMethod({
            type: "card",
            card: elements?.getElement(CardElement),
            billing_details: {
              name : "Englon Kavhuru",
              email : "ekavhuru96@gmail.com",
            },
          }).then(async paymentMethod =>{
            message.success("Stripe payment method successfully created", 3)
            try{
                const data = await axios.post(process.env.REACT_APP_API_URI+"/subscriptions/new", {paymentMethodId: paymentMethod?.paymentMethod?.id,}, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}})
                message.success("Stripe subscription successfully created",  3)
                await stripe?.confirmCardPayment(data.data.clientSecret)
                message.success("Subscription Payment  successfully confirmed",  3)
            }catch(error){
                message.error(error?.message || error?.response?.data?.res, 3);
            }
          }).catch(error=>{
            message.error("Failed to create a Stripe Payment Method", 3)
          }).finally(done =>{
            setState({...state, loading : false});
          })
    }


    return (
        <>
                <div >
                    <div className='payment-container'>
                        <Card>
                            <form onSubmit  = {handleSubmit}>
                                <h4  style={{textAlign : "left"}}>Subscription Bank Card</h4>
                                <div style={{marginTop : "4%", marginBottom : "5%"}}>
                                    <CardElement />
                                </div>
                                <div>
                                    <Button htmlType='submit' loading = {state?.loading} style={{float : "right"}}>Save Payment Method</Button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
        </>
    )
}

export default StripeCard;