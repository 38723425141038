import {DatePicker} from 'antd'
const { RangePicker } = DatePicker;

const DatePickerComponent = ({reportPeriod, setReportPeriod}) => {
    
    return (
        <RangePicker 
            style={{height: "100%", marginRight : "1%", borderRadius:6}} 
            defaultValue={reportPeriod}
            value={reportPeriod}
            // showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD"
            onChange={(period)=>{
            setReportPeriod(period);
            }}
        />
    )
}

export default DatePickerComponent;