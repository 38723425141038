
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Tag
} from "antd";
import moment from "moment"
import {useEffect, useState} from 'react';
import axios from 'axios';
import { PhoneTwoTone, MailTwoTone, EditOutlined, ClockCircleOutlined, SyncOutlined, CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import face2 from "../assets/images/face-2.jpg";
import CorrectiveActionModal from "../components/modals/corrective_action"
import DatePickerComponent from "../components/date/period";

const { Title } = Typography;

// table code start
const columns = [
  {
    title: "INICIDENT",
    dataIndex: "incident",
    key: "incident",
    width: "3%",
  },
  {
    title: "EMPLOYEE RESPONSIBLE",
    dataIndex: "person_responsible",
    key: "person_responsible",
    width: "25%",
  },
  {
    title: "PROPOSED CORRECTIVE ACTIONS",
    dataIndex: "proposed_corrective_action",
    key: "proposed_corrective_action",
    width: "35%",
  },
  {
    title: "PRIORITY",
    dataIndex: "priority",
    key: "priority",
    width: "5",
  },

  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
    width: "5%",
  },

  {
    title: "ACTION",
    key: "status",
    dataIndex: "action",
    width: "5%",
  }
];
function ParticipantsTable({permissionsprop}) {
  const [employees, setEmployees] = useState([])
  const [correctiveActions, setCorrectiveActions] = useState([])
  const [openCorrectiveActionModal, setOpenCorrectiveActionModal] = useState(false)
  const [action_on_form_2, setActionOnFormTwo] = useState({})
  const [incident_id, setIncidentId] = useState({})
  
  const [reportPeriod , setReportPeriod] = useState([moment().startOf("M"), moment().endOf("M")])


  const [permissions, setPermissions] = useState([]);

  useEffect(()=>{
    setPermissions(permissionsprop);
  })

  useEffect(() => {
    refresh()
  }, [permissions, reportPeriod]);

  const refresh = () =>{

    axios.get(process.env.REACT_APP_API_URI+"/correctiveactions?" + `startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      
      formatTableData(data.data.res);
      setCorrectiveActions(data.data.res);

    }).catch(error =>{
      console.log(error)
    })

  }

  const formatTableData = (data) => {
    var arrayList = [];
      data.forEach(element => {
        arrayList.push(
          {

            incident: (
              <>
                <div className="author-info">
                  <p> {element?.incident_id}</p>
                </div>
              </>
            ),
            person_responsible: (
              <>
                <Avatar.Group>
                  <Avatar
                    className="shape-avatar"
                    shape="square"
                    size={40}
                    src={element?.personResponsible?.profilepicture}
                  ></Avatar>
                  <div className="avatar-info">
                    <Title level={5}>{element?.personResponsible?.name}</Title>
                    <p><small><a href={`mailto:${element?.personResponsible?.email}}`}><MailTwoTone />Sent email</a></small> <small><a href={`tel:${element?.personResponsible?.phone}`}><PhoneTwoTone /> Dial phone</a></small> </p>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            proposed_corrective_action: (
              <>
                <div className="author-info">
                  <p> {element.proposed_corrective_actions}</p>
                </div>
              </>
            ),
            priority: (
              <>
                <div className="author-info">
                  <p>{element.priority}</p>
                </div>
              </>
            ),
            status: (
              <>
                {
                  element.status == `waiting`?
                  <Tag icon={<ClockCircleOutlined />} color="default">{element.status.toUpperCase()}</Tag>
                  :
                  element.status == `under way`?
                  <Tag icon={<SyncOutlined spin />} color="processing">{element.status.toUpperCase()}</Tag>
                  :
                  element.status == `completed`?
                  <Tag  icon={<CheckCircleOutlined />} color="success">{element.status.toUpperCase()} </Tag>
                  :
                  <Tag icon={<MinusCircleOutlined />}  color="error">{element.status.toUpperCase()} </Tag>
                }
              </>
            ),
            action: (
              <>
              {
                permissions.some(x=>x.permission == 'Update_CorrectiveAction')?
                  <Button type="" 
                  value={element.id}
                  onClick = {()=>{
                    setActionOnFormTwo(`edit`)
                    setIncidentId(element.incident_id)
                    setOpenCorrectiveActionModal(true)
                  }}
                  ><EditOutlined /> Edit</Button>
                  :
                  ""
              }
                
              </>
            ),
          }
        )
      });
      setEmployees(arrayList);
  }

  const search = (value) =>{
    const filteredData = correctiveActions.filter(x =>
        {
          console.log(x)
          return x?.creator?.name?.toUpperCase().includes(value.toUpperCase())||
           x?.personResponsible?.name?.toUpperCase().includes(value.toUpperCase())||
           x?.status?.toUpperCase().includes(value.toUpperCase())||
           x?.priority?.toUpperCase().includes(value.toUpperCase())||
           x?.incident_id == value
        }
      );
    formatTableData(filteredData);
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="INCIDENT CORRECTIVE ACTIONS"
              extra={
                <>
                  <Row>
                    <Col>
                      <DatePickerComponent reportPeriod={reportPeriod} setReportPeriod={setReportPeriod}/>
                    </Col>
                    <Col>
                      <Input onChange={(event) => search(event.target.value)} placeholder="Search Corrective Action..." />
                    </Col>

                    <Col>
                        <a  href={process.env.REACT_APP_API_URI+'/correctiveactions/excel?token='+localStorage.getItem("token")+`&startDate=${reportPeriod[0]}&endDate=${reportPeriod[1]}`} >
                          <Button> EXPORT EXCEL</Button>
                        </a>
                    </Col>

                    </Row>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={employees}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <CorrectiveActionModal 
        action_value = {action_on_form_2}
        setOpenCorrectiveActionModal = {setOpenCorrectiveActionModal} 
        show = {openCorrectiveActionModal} 
        incidentId = {incident_id}
        refresh ={refresh}
      />
    </>
  );
}

export default ParticipantsTable;
