import { useState, useEffect } from "react";
import axios from 'axios';

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
} from "@ant-design/icons";

import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import ava1 from "../assets/images/logo-shopify.svg";
// import team1 from "../assets/images/team-1.jpg";
import card from "../assets/images/info-card-1.jpg";
import moment from "moment";

const profile = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
      fill="#fff"
    ></path>
    <path
      d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
      fill="#fff"
    ></path>
    <path
      d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
      fill="#fff"
    ></path>
    <path
      d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
      fill="#fff"
    ></path>
  </svg>,
];
const heart = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
      fill="#fff"
    ></path>
  </svg>,
];
const cart = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
      fill="#fff"
    ></path>
  </svg>,
];


var count_display = [
  {
    today: `This Month's Incidents`,
    title: 0,
    icon: cart, //dollor,
    bnb: "bnb2",
  },
  {
    today: "Today's Incidents ",
    title: 0,
    icon: cart, //profile,
    bnb: "bnb2",
  },
  {
    today: "Total Clients",
    title: 0,
    icon: heart,
    bnb: "redtext",
  },
  {
    today: "Total Employees",
    title: 0,
    icon: profile,
    bnb: "bnb2",
  },
];

function Home({token, permissionsprop}) {
  const [incidentMonthlyAnalytics, setIncidentMonthlyTotals] = useState({});
  const [participantsMontlyAnalytics, setParticipantsMontlyAnalytics] = useState({});
  const [recentlyAddedIncidents, setRecentlyAddedIncidents] = useState([]);
  const [participantsWithTopIncidents, setParticipantsWithTopIncidents] = useState([]);
  const [count, setCounts] = useState(count_display)

  useEffect(() => {

    axios.get(process.env.REACT_APP_API_URI+"/analytics/incidents/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setIncidentMonthlyTotals(data.data.res)
    }).catch(error =>{
      console.log(error)
    })
    axios.get(process.env.REACT_APP_API_URI+"/analytics/participants/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setParticipantsMontlyAnalytics(data.data.res)
    }).catch(error =>{
      console.log(error)
    })

    axios.get(process.env.REACT_APP_API_URI+"/analytics/summaries/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      count_display[0].title = data.data.res.incident_total
      count_display[1].title = data.data.res?.incident_today_total
      count_display[2].title = data.data.res?.participants_total
      count_display[3].title = data.data.res?.employees_total
      setCounts(count_display)
    }).catch(error =>{
      console.log(error)
    })

    axios.get(process.env.REACT_APP_API_URI+"/analytics/recentincidents/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      var temp = [];
      
      data.data.res.forEach(element => {
        temp.push({
          img: ava1,
          description_during: element.description_during,
          reporter_to : (
            <div className="avatar-group mt-2">
              <Tooltip placement="bottom" title={element.reportedTo?.name}>
                <img  className="tootip-img" src={element?.reportedTo?.profilepicture} alt="" /> {element.reportedTo?.name}
              </Tooltip>
            </div>
          ),
          reporter: (
            <div className="avatar-group mt-2">
              <Tooltip placement="bottom" title={element.creator?.name}>
                <img className="tootip-img" src={element?.creator?.profilepicture} alt="" /> {element.creator?.name}
              </Tooltip>
            </div>
          ),
          datetime: `${moment(element.date).format("DD/MM/YYYY")} ${moment(element.time).format('HH:mm')}`
        })
      });
      setRecentlyAddedIncidents(temp)
    }).catch(error =>{
      console.log(error)
    })

    axios.get(process.env.REACT_APP_API_URI+"/analytics/topincidents/participants", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      var temp = [];
      var pos =1;
      data.data.res.forEach(element=>{
        temp.push({
          title: `${element.name}`,
          time: `# ${element.totalincidents} recorded incidents`,
          color: pos<=3?"red" : "green",
        })
        pos++;
      })
      setParticipantsWithTopIncidents(temp)
    }).catch(error =>{
      console.log(error)
    })
  }, [token]);

  const { Title, Text } = Typography;

  const [reverse, setReverse] = useState(false);


  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };



  const EllipsisMiddle = ({ suffixCount, children }) => {
    const start = children.slice(0, children.length - suffixCount).trim();
    const suffix = children.slice(-suffixCount).trim();
    return (
      <Text
      className="text-xs font-weight-bold"
        style={{
          maxWidth: '200px',
        }}
        ellipsis={{
          suffix,
        }}
      >
        {start}
      </Text>
    );
  };

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}></small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart data = {incidentMonthlyAnalytics} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart data= {participantsMontlyAnalytics} />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title className="mb-4" level={5}>Recent Incidents</Title>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>WHAT HAPPENED DURNG THE INCIDENT</th>
                      <th>REPORTER</th>
                      <th>REPORTED TO</th>
                      <th>DATE & TIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentlyAddedIncidents.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <div >
                            <EllipsisMiddle suffixCount={12}>                            
                              {d.description_during+" "}
                            </EllipsisMiddle>
                          </div>
                        </td>
                        <td className="text-xs font-weight-bold">{d.reporter}</td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.reporter_to}{" "}
                          </span>
                        </td>
                        <td>
                          <div className="text-xs font-weight-bold">{d.datetime}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <Title className="mb-4" level={5}>Top Incidents Rankings</Title>
                <Timeline
                  className="timelinelist"
                  reverse={reverse}
                >
                  {participantsWithTopIncidents.map((t, index) => (
                    <Timeline.Item color={t.color} key={index}>
                      <Title level={5}>{t.title}</Title>
                      <Text>{t.time}</Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
                <Button
                  type="primary"
                  className="width-100"
                  onClick={() => setReverse(!reverse)}
                >
                  {<MenuUnfoldOutlined />} REVERSE
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default Home;
