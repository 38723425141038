import { Button, Modal, Form, Input, Select, Row, Col, DatePicker, TimePicker, List } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const { TextArea } = Input;
const App = ({show, setOpenAccessLevelModal, showDrawer, action, accessLevelId }) => {
  useEffect(() => {
    if(action == `create`){
      clear();
    }
    axios.get(process.env.REACT_APP_API_URI+"/auth/permissions/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      var data_list = [];
      data.data.res.forEach(element =>{
        data_list.push({
          label : element.permission,
          value : element.id
        })
      })
      setPermissions(data_list);
    }).then(next=>{
        if(action == `edit`){
          axios.get(process.env.REACT_APP_API_URI+`/auth/accesslevels/${accessLevelId}/permissionsids`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
            setName(data.data.res.name)
            setPermissionIds(data.data.res.permissions)
          }).catch(error =>{
            console.log(error)
          })
        }
    }).catch(error =>{
      console.log(error)
    })

  }, [show]);

  const clear = () =>{
    setName("");
    setPermissionIds([]);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const { Option } = Select;
  const [permissions, setPermissions] = useState([])
  const [name, setName] = useState("")
  const [permission_ids, setPermissionIds] = useState([])


  const submit = (event) =>{
    const data = {
      name : name,
      permissions : permission_ids
    } 
    axios.post(process.env.REACT_APP_API_URI+"/auth/accesslevelwithpermissions/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      console.log(res)
      showDrawer();
      setLoading(false);
      setOpenAccessLevelModal(false);
    }).catch(error=>{
      if (error.response) {
        setLoading(false)
        // Server Error Massage
        console.log(error.response.data?.res);
      } else if (error.request) {
        // Request was made but no response
        console.log(error.request);
      } else {
        // Network Error
        console.log('Error', error.message);
      }
    })
  }

  const edit = (event) =>{
    var data = [];
    permission_ids.forEach(element => {
      data.push({
        access_level_id : accessLevelId,
        permission_id : element
      } )
    });
    axios.put(process.env.REACT_APP_API_URI+"/auth/accesslevelwithpermissions/", {name : name, permissions : data} , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      setOpenAccessLevelModal(false);
      showDrawer();
      setLoading(false)
    }).catch(error=>{
      setLoading(false)
      if (error.response) {
        // Server Error Massage
        console.log(error.response.data?.res);
      } else if (error.request) {
        // Request was made but no response
        console.log(error.request);
      } else {
        // Network Error
        console.log('Error', error.message);
      }
    })
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
    <Form
        layout="vertical"
        className="row-col"
      >
      <Modal 
      title="ACCESS LEVEL" 
      visible={isModalVisible} 
      onOk={()=>setOpenAccessLevelModal(false)} 
      onCancel={()=>setOpenAccessLevelModal(false)}
      footer={[
        <Button key="submit" type=""  onClick={()=>{setOpenAccessLevelModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
        <Button key="submit" type="primary" loading={loading} onClick={()=>{submit();setLoading(true)}}>
            Submit
        </Button>
        :
        <Button key="submit" type="primary" loading={loading} onClick={()=>{edit();setLoading(true)}}>
            Save
        </Button>
      ]}
      >
        <Form.Item>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Type Access Level... " />
        </Form.Item>
        <Form.Item>
        <Row>
            <Select
              mode="multiple"
              value={permission_ids}
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onChange={(event)=>{
                setPermissionIds(event);
              }}
              options={permissions}
            />
          </Row>
        </Form.Item>
        
      </Modal>
      </Form>
    </>
  );
};

export default App;