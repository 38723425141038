
import { Switch, Route, } from "react-router-dom";
import Home from "./pages/Home";
import Employess from "./pages/Employess";
import IncidentRegisterTable from "./pages/IncidentRegitster"
import ParticipantsTable from "./pages/Participants";
import CorrectiveActionsTable from "./pages/CorrectiveActions";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import Password from "./pages/password";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/incidentTable.css"
import "./assets/styles/responsive.css";
import 'animate.css';
import {useEffect, useState} from 'react'
import axios from 'axios'
import { Row } from "antd";
import LockedAccount from "./pages/LockedAccount";
import StripeCard from "./pages/StripeCheckout.js";
import RegistrationSuccess from "./pages/Registration.success";
// import 'bootstrap/dist/css/bootstrap.css';

function App() {
  const [permissions, setPermissions] = useState([])

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URI+"/auth/my/permissions/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setPermissions(data.data.res);
    }).catch(error =>{
      console.log(error)
    })
  },[])
  
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/" exact component={SignIn} />
        <Route path="/registration/success" exact component={RegistrationSuccess} />
        <Route exact path="/password" render={(props) => <Password token={localStorage.getItem("token")}/>}   />
        <Main permissionsprop={permissions}>
          <Route exact path="/dashboard"  render={(props) =><Home permissionsprop={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/employees" render={(props) => <Employess permissionsprop = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/incidents" render={(props) => <IncidentRegisterTable  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/participants" render={(props) => <ParticipantsTable  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/correctiveactions" render={(props) => <CorrectiveActionsTable  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/billing" render={(props) => <Billing permissions ={permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/rtl" render={(props) => <Rtl permissionsprop = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/profile" render={(props) => <Profile  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
          <Route exact path="/locked" render={(props) => <LockedAccount  permissionsprop = {permissions}  token={localStorage.getItem("token")}/>}   />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
