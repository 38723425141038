
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Tag
} from "antd";

import {useEffect, useState} from 'react';
import EmployeeModal from "../components/modals/employee"
import axios from 'axios';
import { PhoneTwoTone, MailTwoTone, EditOutlined } from "@ant-design/icons";
import face2 from "../assets/images/face-2.jpg";

import PictureUpload from '../components/modals/pictureupload';

const { Title } = Typography;

// table code start
const columns = [
  {
    title: "EMPLOYEE",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    key: "function",
  },
  {
    title: "ACCESS LEVEL",
    dataIndex: "accesslevel",
    key: "function",
    width: "15%",
  },

  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
    width: "7%",
  },

  {
    title: "ACTION",
    key: "status",
    dataIndex: "action",
    width: "7%",
  }
];
function EmployeesTable({permissionsprop}) {
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
  const [employees, setEmployees] = useState([])
  const [employee, setEmployee] = useState(null);
  const [action, setAction] = useState('create');
  const [openPictureUploadModal, setOpenPictureUploadModal] = useState(false)
  const [permissions, setPermissions] = useState([]);

  useEffect(()=>{
    setPermissions(permissionsprop);
  })

  useEffect(() => {
    refresh();
  }, [permissions]);

  const refresh = () => {
    axios.get(process.env.REACT_APP_API_URI+"/employees/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      var arrayList = [];
      data.data.res.forEach(element => {
        arrayList.push(
          {
            key: "1",
            name: (
              <>
                <Avatar.Group>
                  <div onClick={()=>{setEmployee(element); setOpenPictureUploadModal(true)}}>
                    <Avatar
                      className="shape-avatar"
                      shape="square"
                      size={40}
                      alt={`${element.name.split(" ").length > 1? `${element.name.split(" ")[0]} ${element.name.split(" ")[1]}`:""}`}
                      src={element.profilepicture}
                    ></Avatar>
                  </div>
                  
                  <div className="avatar-info">
                    <Title level={5}>{element.name}</Title>
                    <p><small><MailTwoTone /> {element.email}</small> <small><PhoneTwoTone /> {element.phone}</small> </p>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            address: (
              <>
                <div className="author-info">
                  <p> {element.address}</p>
                </div>
              </>
            ),
            accesslevel: (
              <>
                <div className="author-info">
                  <p>{element.access_level?.name}</p>
                </div>
              </>
            ),
            status: (
              <>
                {
                  true?
                  <Tag color={"green"} key={`Active`}>
                    {`Active`.toUpperCase()}
                  </Tag>
                  :
                  <Tag color={"red"} key={`Active`}>
                    {`Active`.toUpperCase()}
                  </Tag>
                }
              </>
            ),
            action: (
              <>
                {
                  permissions.some(x=>x.permission == "Update_User")?
                  <Button onClick={()=>{setAction('edit'); setEmployee(element); setOpenEmployeeModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                  :
                  ""
                }
                {
                  permissions.some(x=>x.permission == "Delete_User")?
                  <Button onClick={()=>{deleteEmployee(element.id)}} type="" className="ant-btn-sm m-5" danger>Delete</Button> 
                  :
                  ""
                }
              </>
            ),
          }
        )
      });
      setEmployees(arrayList);
    }).catch(error =>{
      console.log(error)
    })
  }

  const deleteEmployee = (id) =>{
    axios.delete(process.env.REACT_APP_API_URI+`/employees/?id=${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      refresh();
    }).catch(error =>{
      console.log(error) 
    })
  }

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="EMPLOYEES"
              extra={
                <>
                  <Row>
                    <Col>
                      <Input placeholder="Seach Employee" />
                    </Col>
                    <Col>
                      <Button onClick={()=>{setAction(`create`);setOpenEmployeeModal(true); console.log("open")}} >ADD EMPLOYEE</Button>
                    </Col>
                    </Row>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={employees}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <EmployeeModal refresh={refresh} employee={employee} action={action} setOpenEmployeeModal = {setOpenEmployeeModal} show = {openEmployeeModal}/>
      <PictureUpload show={openPictureUploadModal} refresh ={refresh} setOpenPictureUploadModal={setOpenPictureUploadModal} id={employee?.id}/>
    </>
  );
}

export default EmployeesTable;
