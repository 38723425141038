

import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import React, {Component,useEffect, useState} from "react"
const { Title, Paragraph } = Typography;

export default class LineChart extends Component {
  constructor(props){
    super(props)
    this.state = {
      series : []
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.data != this.props.data){
      var temp = lineChart.series;
      temp[0].data = this.props.data.participantsJoinedWithinMonth;
      temp[1].data = this.props.data.participantsCumulativeGrowth;
      this.setState({series : temp})
    }
  }
  render(){

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Active Participants</Title>
          <div className="lastweek">
            Monthly total participants
          </div>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={this.state.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
  }
}
