import { Button, Modal, Form, Input, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import momenttimezone from 'moment-timezone';


const App = ({show, setOpenParticipantModal, action, refresh, participant}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show)
  }

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [ndisnumber, setNdisNumber] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")

  const clear = () =>{
    setName("")
    setEmail("")
    setNdisNumber("")
    setPhone("")
    setAddress("")
  }
  
  useEffect(()=>{
    if(action == `create`){
      clear();
    }
    if(action == `edit`){
        setName(participant?.name)
        setEmail(participant?.email)
        setAddress(participant?.ndisnumber)
        setPhone(participant?.phone)
        setAddress(participant?.address)
        setNdisNumber(participant?.ndisnumber)
        setLoading(false)
    }
  },[show])

   const submit = (event) =>{
      const data = {
        name : name,
        email : email,
        ndisnumber : ndisnumber,
        phone : phone,
        address : address,
        timeZone : momenttimezone.tz.guess()
      } 
    axios.post(process.env.REACT_APP_API_URI+"/participants/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      console.log(res)
      setLoading(false);
      setOpenParticipantModal(false);
      clear();
      refresh()
    }).catch(error=>{
      if (error.response) {
        setLoading(false)
        // Server Error Massage
        console.log(error.response.data?.res);
      } else if (error.request) {
        // Request was made but no response
        console.log(error.request);
      } else {
        // Network Error
        console.log('Error', error.message);
      }
    })
  }


  const edit = (event) =>{
    const data = {
      name : name,
      email : email,
      ndisnumber : ndisnumber,
      phone : phone,
      address : address,
    } 
  axios.put(process.env.REACT_APP_API_URI+`/participants/?id=${participant.id}`, data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
    console.log(res)
    setLoading(false);
    setOpenParticipantModal(false)
    refresh()
  }).catch(error=>{
    if (error.response) {
      setLoading(false)
      // Server Error Massage
      console.log(error.response.data?.res);
    } else if (error.request) {
      // Request was made but no response
      console.log(error.request);
    } else {
      // Network Error
      console.log('Error', error.message);
    }
  })
}


  const [loading, setLoading] = useState(false)

  return (
    <>
    <Modal 
      title={`PARTICIPANT DETAILS ${action != `create`? `FOR #${participant.id}`: ``}`}
      visible={isModalVisible} 
      onOk={()=>setOpenParticipantModal(false)} 
      onCancel={()=>setOpenParticipantModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenParticipantModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
        <Button key="submit" type="primary" loading={loading} onClick={()=>{submit();setLoading(true)}}>
            Submit
        </Button>
        :
        <Button key="submit" type="primary" loading={loading} onClick={()=>{edit();setLoading(true)}}>
            Save
        </Button>
      ]}
      >
    <Form
        layout="vertical"
        className="row-col"
      >
      
        <Form.Item
        label = "Full Name"
        rules={[
            { required: true, message: "Please input your name!" },
        ]}
        >
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Name e.g Joe Doe" />
        </Form.Item>
        
        <Row>
          <Col className='m-5 mt-0 mb-0'  md={11} xl={11} lg={11}>
            <Form.Item
            label = "Phone Number"
            rules={[
                { required: true, message: "Please input your phone number!" },
            ]}>
              <Input value={phone} onChange={(event)=>setPhone(event.target.value)} name="phone" placeholder="Phone Number e.g +64 990 008 9099" />
            </Form.Item>
          </Col>
          <Col className='m-5 mt-0 mb-0' sm={24} md={11} xl={11} lg={11}>
            <Form.Item
            label = "NDIS Number"
            rules={[
                { required: true, message: "Please input your NDIS Number!" },
            ]}>
              <Input value={ndisnumber} onChange={(event)=>setNdisNumber(event.target.value)} name="ndisnumber" placeholder="NDIS Number e.g AWE9099" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
            label = "Email"
            rules={[
                { required: true, message: "Please input your email!" },
            ]}>
          <Input value={email} onChange={(event)=>setEmail(event.target.value)} name = "email" placeholder="Email e.g exampla@test.com" />
        </Form.Item>

        <Form.Item
            label = "Address"
            rules={[
                { required: true, message: "Please input your Address!" },
            ]}>
          <Input value={address} onChange={(event)=>setAddress(event.target.value)} name = "address" placeholder="Address location e.g 173 NSW, Australia" />
        </Form.Item>
      </Form>
      </Modal>
    </>
  );
};

export default App;