/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Tarisa PTY LTD (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Tarisa PTY LTD
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import {Elements} from '@stripe/react-stripe-js'
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51Mt3a3IIuAEYzF1fODhYYQrSiRLMEVLIUpcnpxoBD8DQTs9GqUZ6iSWFFNce7lzrmh6hrMtTvz3g3RGjoOLxBzvc00IryMQnqc")

ReactDOM.render(
  <BrowserRouter>
  <Elements stripe={stripePromise}>
    <App />
  </Elements>
  </BrowserRouter>,
  document.getElementById("root"),
);
