import {Modal, Form, Input, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import momenttimezone from 'moment-timezone';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

const App = ({show, setOpenFileUploadModal, id, refresh}) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [openned, setOpen] = useState(false);
  if(show != openned){
    setOpen(show);
  }

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('doc', file);
    });
    setUploading(true);
      fetch(process.env.REACT_APP_API_URI+`/incidents/upload/many?incidentid=${id}`, {
        method: 'POST', 
        body: formData,
      }).then(() => {
        if (refresh) refresh();
        setFileList([]);
        message.success('upload successfully.');
        setOpenFileUploadModal(false);
      })
      .catch(() => {
        // setOpenFileUploadModal(false);
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  return (
    <>
        <Modal
        title = {`ADD SUPPORTING INCIDENT DOCUMENNTS?`}
        visible = {openned}
        onCancel = {()=>setOpenFileUploadModal(false)}
        footer = {[
            <Button key="" type='danger' style={{color:"red", backgroundColor:"white"}}  onClick={()=>{setOpenFileUploadModal(false)}}>
                No, Exit
            </Button>,
            <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
            marginTop: 16,
            }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>,
        ]}
        >
        <Upload name='doc' {...props}>
            <Button icon={<UploadOutlined />}>Select Files</Button>
        </Upload>
        
        </Modal>
    </>
      
  );
};

export default App;