import { Button, Modal, Form, Input, Select, Row, Col, DatePicker, TimePicker, Space, message, Table, Popover, Checkbox, Radio } from 'antd';
import { DeleteFilled, DeleteTwoTone } from "@ant-design/icons";
import React, { useState, useEffect, setState } from 'react';
import axios from 'axios';
import date_time from 'date-and-time';
import moment from 'moment';
import CorrectiveActionModal from "./corrective_action";
import "../../assets/styles/incidentmodal.css"
import FileUpload from './fileupload';
import '../../assets/styles/incident.css'
const { TextArea } = Input;
const { Option } = Select;

const App = ({show, setOpenIncidentModal, refresh, incident , data, action}) => {

  const [openCorrectiveActionModal, setOpenCorrectiveActionModal] = useState(false)
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);

  const [employees, setEmployees] = useState([])
  const [categories, setCategories] = useState([])
  const [participants, setParticipants] = useState([])
  const [reported_to, setReportedTo] = useState("")
  const [participant_id, setParticipantId] = useState("")
  const [date, setDate] = useState("");
  const [time, setTime] = useState("00:00:00")
  const [location, setLocation] = useState("")
  const [descriptionBefore, setDescriptionBefore] = useState(data?.descriptionBefore)
  const [descriptionDuring, setDescriptionDuring] = useState("")
  const [descriptionAfter, setDescriptionAfter] = useState("")
  const [action_on_form_2, setActionOnFormTwo] = useState({})
  const [incident_id, setIncidentId] = useState(null);
  const [witnesses, setWitnesses] = useState([])
  const [victims, setVictims] = useState([])
  const [incidentCategoryAndAssessments, setIncidentCategoryAndAssessments] = useState([])
  const [incidentCategory, setIncidentCategory] = useState({})

  const [isReportable, setIsReportable] = useState(false);
  const [policeRefNumber, setPoliceRefNumber] = useState("")

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URI+"/employees/less", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(res =>{
      setEmployees(res.data.res);
    }).catch(error =>{
      console.log(error)
    })
    axios.get(process.env.REACT_APP_API_URI+"/participants/less", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setParticipants(data.data.res);
    }).catch(error =>{
      console.log(error)
    })

    axios.get(process.env.REACT_APP_API_URI+"/categories", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setCategories(data.data);
    }).catch(error =>{
      console.log(error)
    })

  }, [show]);

  const clear = () =>{
    setReportedTo("")
    setParticipantId("")
    setDate("")
    setTime("")
    setLocation("")
    setDescriptionBefore("")
    setDescriptionDuring("")
    setDescriptionAfter("")
    setWitnesses([])
    setPoliceRefNumber("");
    setIsReportable(false);
    setIncidentCategoryAndAssessments([]);
  }
  
  useEffect(() => {
      if(action == `create`){
        clear();
      }
      if(action == `edit` || action == `opening`){
        setReportedTo(incident?.reported_to)
        setParticipantId(incident?.participant_id)
        setDate(moment(incident?.date))
        setTime(incident?.time)
        setLocation(incident?.location)
        setDescriptionBefore(incident?.description_before)
        setDescriptionDuring(incident?.description_during)
        setDescriptionAfter(incident?.description_after)
        setWitnesses((incident?.witnesses || []).concat([]))
        setVictims(incident?.victims)
        setPoliceRefNumber(incident?.policeRefNumber)
        setIsReportable(incident?.isReportable)
        setIncidentCategoryAndAssessments(incident?.incidentCategoryAndAssessments)
      }
              
  }, [show]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const create = ({isDraft}) =>{
    const data = {
      reported_to : reported_to,
      participant_id : participant_id,
      date : date,
      time : time,
      location : location,
      description_before : descriptionBefore,
      description_during : descriptionDuring,
      description_after : descriptionAfter,
      witnesses : witnesses.filter(x => x !== {key : "create"}),
      policeRefNumber : policeRefNumber,
      isReportable : isReportable,
      victims : victims,
      isDraft : isDraft,
      incidentCategoryAndAssessments : incidentCategoryAndAssessments
    } 
  axios.post(process.env.REACT_APP_API_URI+"/incidents/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
    message.success(res.data.res)
    refresh();
    setLoading(false);
    setIncidentId(res.data.data.id);
    setOpenFileUploadModal(true);
    setOpenIncidentModal(false)
  }).catch(error=>{
    if (error.response) {
      setLoading(false)
      // Server Error Massage
      message.error(error.response.data?.res)
    } else if (error.request) {
      // Request was made but no response
      message.error(error.request)
    } else {
      // Network Error
      message.error(error.message)
      console.log('Error', error.message);
    }
  })
}



const edit = ({isDraft}) =>{
  const object = {
    reported_to : reported_to,
    participant_id : participant_id,
    date : date,
    time : time,
    location : location,
    description_before : descriptionBefore,
    description_during : descriptionDuring,
    description_after : descriptionAfter,
    witnesses : witnesses.filter(x => x?.key !== "create"),
    policeRefNumber : policeRefNumber,
    isReportable : isReportable,
    victims : victims,
    isDraft : isDraft,
    incidentCategoryAndAssessments : incidentCategoryAndAssessments
  
  } 
  axios.put(process.env.REACT_APP_API_URI+`/incidents/?id=${incident?.id}`, object , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
    setOpenIncidentModal(false);
    refresh();
    setLoading(false);
  }).catch(error=>{
    console.log(error)
    if (error.response) {
      setLoading(false)
      // Server Error Massage
      console.log(error.response);
    } else if (error.request) {
      // Request was made but no response
      console.log(error.request);
    } else {
      // Network Error
      console.log('Error', error.message);
    }
  })
}

  const [loading, setLoading] = useState(false);


  const IncidentCategories = ({c}) =>{

    return <Space wrap>
    {c.map((item) => (
      <Popover content={
        <div>
          {
            item?.keypoints? item.keypoints.map((keypoint)=>
              <p style={{width : "100%"}}> {keypoint.name}</p>
            )
            :
            ""
          }
        </div>
      } title="Key Points">
        <Button
           style={((incidentCategoryAndAssessments || []).some(x => x.category_id === item?.id) || incidentCategory.category_id == item?.id) ? {color : "blue", borderColor : "blue"}:{}} 
          onClick={()=>{
            setIncidentCategory({...incidentCategory, category_id : item?.id});
          }}
          >{item.name}</Button>
      </Popover>
    ))}
  </Space>   
  }

  const IncidentCategoriesAssessMents = ({c}) =>{

    return <Space wrap>
    {c.map((item) => (
      <Popover content={
        <div >
          {
            item?.keypoints? item.keypoints.map((keypoint)=>
              <p style={{width : "100%"}}> {keypoint.name}</p>
            )
            :
            ""
          }
        </div>
      } title="Key Points">
        <Button style={incidentCategoryAndAssessments.find(x => x.assessment_id == item?.id) && {color : "blue", borderColor : "blue"}} 
        onClick={()=>{ 

            if(incidentCategoryAndAssessments.find(x => x?.assessment_id == item?.id)){
              setIncidentCategoryAndAssessments(incidentCategoryAndAssessments.filter(x =>x.assessment_id != item?.id));
            }else{
              setIncidentCategoryAndAssessments([...incidentCategoryAndAssessments, {category_id: incidentCategory?.category_id, assessment_id : item?.id}])
            }

          }} >{item.name}</Button>
      </Popover>
    ))}
  </Space>   
  }

  return (
    <>
        <Modal 
          width={window.innerWidth<850? `100%` : `${0.6*window.innerWidth}px`}
          title="INCIDENT REPORT FORM" 
          visible={isModalVisible} 
          onOk={()=>setOpenIncidentModal(false)} 
          onCancel={()=>setOpenIncidentModal(false)}
          footer={[
            <Button key="submit" type='danger' style={{color:"red", backgroundColor:"white"}}  onClick={()=>{setOpenIncidentModal(false)}}>
                Cancel
            </Button>,
            <Button style={{background:"#798077", borderColor:"#798077"}} key="submit" type="primary" loading={loading} onClick={()=>{action==`create`?create({isDraft : true}) : edit({isDraft : true}) ;setLoading(true)}}>
              {`Submit as Draft`}
          </Button>,
            action != `create`?
            <Button key="submit" type=""  onClick={()=>{setActionOnFormTwo(`create`);setOpenIncidentModal(true); setOpenCorrectiveActionModal(true)}}>
              Corective Ation
            </Button>
            :
            ""
            ,
            action != `opening`?
              <Button key="submit" type="primary" loading={loading} onClick={()=>{action==`create`?create({isDraft : false}) : edit({isDraft : false}) ;setLoading(true)}}>
                {action == `create`?`Submit`:`Save`}
              </Button>
              :
              <a href = {process.env.REACT_APP_API_URI+`/incidents/pdf/${incident.id}`}>
                <Button key="submit" type="">
                  Download PDF
                </Button>
              </a>
              
          ]}
          >
        <Form
            layout="vertical"
            className="row-col"
          >
            <Form.Item className='m-0'>
              <Row className='m-0'>
                <Col className='' xs={11} sm={12} md={12} lg={12}>
                <Form.Item label="Select Participant">
                  <Select
                      value={participant_id}
                      showSearch
                      onChange={(value)=>setParticipantId(value)}
                      placeholder="Select Participant"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.includes(input)}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {
                        participants.map(item =>
                          <Option value={item.id}>{item.name}</Option>
                          )
                      }
                  </Select>
                </Form.Item>
                  
                </Col>
                <Col style={{paddingLeft:5}} className='m-0' xs={12} sm={12} md={12} lg={12}>
                <Form.Item className='m-0' label="Report to">
                  <Select className='m-0'
                    showSearch
                    value={reported_to}
                    onChange={(value)=>setReportedTo(value)}
                    placeholder="Report the Incident to..."
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.includes(input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {
                      employees?.map(item =>
                        <Option value={item.id}>{item.name}</Option>
                        )
                    }
                  </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            
            <Form.Item className='m-1'>
              <Row className='m-0'>
                <Col  xs={11} sm={12} md={12} lg={12} className='m-0'>
                  <Form.Item label="Incident Date" className='m-0'>
                    <DatePicker value={date} style={{width : "100%"}}    onChange={(value)=>setDate(value)} name = "date" placeholder="Incident Date" />
                  </Form.Item>
                </Col>
                <Col style={{paddingLeft:5}} xs={11} sm={12} md={12} lg={12} className='m-0'>
                  <Form.Item label = "Incident Time" className='m-0'>
                    <TimePicker value={moment(`${moment(date? date : moment()).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss')} style={{width : "100%"}}  onChange={(value)=>{setTime(value.format('HH:mm:ss')); }} name = "time" placeholder="Incident Time" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Incident Location">
              <Input  value={location}  onChange={(event)=>setLocation(event.target.value)} name = "location" placeholder="location e.g 173 NSW, Australia" />
            </Form.Item>

            <Form.Item label = "Description of what happenned before the incident">
              <TextArea  value={descriptionBefore}  autoSize={{minRows: 3, maxRows: 8}} placeholder="Tell us what happen before the incident here..." allowClear onChange={(event)=>setDescriptionBefore(event.target.value)}/>
            </Form.Item>

            <Form.Item label = "Description of what happened during the Incident">
              <TextArea  value={descriptionDuring} autoSize={{minRows: 3, maxRows: 8}} placeholder="Tell us what happen during the incident here..." allowClear onChange={(event)=>setDescriptionDuring(event.target.value)}/>
            </Form.Item>

            <Form.Item label = "Description of what happened after the Incident">
              <TextArea  value={descriptionAfter}  autoSize={{minRows: 3, maxRows: 8}} placeholder="Tell us what happen after the incident here..." allowClear onChange={(event)=>setDescriptionAfter(event.target.value)}/>
            </Form.Item>

            <Form.Item label = "Incident Witnesses">
              <Witnesses A={'+'} w={witnesses} setWitnesses = {setWitnesses}/>
            </Form.Item>

            <Form.Item label = "Other People Involved In the Incident">
              <Victims A={'+'} v={victims}  employees={employees} participants={participants}  setVictims = {setVictims}/>
            </Form.Item>

            <Form.Item label = "Incident Categories">
               <IncidentCategories c = {categories}/>
            </Form.Item>

            <Form.Item label = "Incident Assessment">
               <IncidentCategoriesAssessMents  c = {categories.filter(x => x.id == incidentCategory?.category_id)[0]?.assessments || []}/>
            </Form.Item>
            

            <Form.Item label="Is the incident reportable?">
              <Checkbox  value={isReportable} checked={!isReportable}  onChange={(event)=>setIsReportable(false)} name = "location" placeholder="location e.g 173 NSW, Australia" >No, it's not Reportable </Checkbox>
              <Checkbox  value={isReportable} checked={isReportable} onChange={(event)=>setIsReportable(true)} name = "location" placeholder="location e.g 173 NSW, Australia" >Yes, it's Reportable</Checkbox>
            </Form.Item>

              <Form.Item label="Provide Incident Police Reference">
                <Input  value={policeRefNumber}  onChange={(event)=>setPoliceRefNumber(event.target.value)} name = "location" placeholder="Police Reference Number here..." />
              </Form.Item>

          </Form>
          </Modal>
          <CorrectiveActionModal 
            action_value = {action_on_form_2}
            setOpenCorrectiveActionModal = {setOpenCorrectiveActionModal} 
            show = {openCorrectiveActionModal} 
            incidentId = {incident?.id}
            refresh = {()=>{}}
          />

      <FileUpload refresh={refresh} show ={openFileUploadModal} setOpenFileUploadModal = {setOpenFileUploadModal} id = {incident_id}/>
    </>
  )
};

export default App;




const Witnesses = ({w, A , setWitnesses}) =>{
  const [list, setList] = useState([]);
  useEffect(()=>{
    setList(w)
  }, [w])

  const [witness, setWitness] = useState({});

  const handleChange = e => {
    console.log(e.target)
      const { name, value } = e.target;
      setWitness(prevState => ({
          ...prevState,
          [name]: value
      }));
  };

  const deleteElement = (object) =>{
    setWitnesses(list.filter(x => x != object))
  }

  const cols = [
    {
      title: "NAME",
      width: "32%",
      editable: true,
      render(data){
        return data.key != 'create'?
        <div>{data?.name}</div>
        :
        <div className="author-info">
          <Input value={witness?.name} name='name' onChange={handleChange}></Input>
        </div>
      }
    },
    {
      title: "PHONE NUMBER",
      render(data){
        return data.key != 'create'?
        <div>{data?.phone}</div>
        :
        <div className="author-info">
          <Input  value={witness?.phone} name='phone' onChange={handleChange}></Input>
        </div>
      }
    },
    {
      title: "EMAIL ADDRESS",
      render(data){
        return data.key != 'create'?
        <div>{data?.email}</div>
        :
        <div className="author-info">
          <Input  value={witness?.email} name='email' onChange={handleChange}></Input>
        </div>
      }
    },
    {
      title: "ACTION",
      render(data){
        return data?.key == 'create'?
        <>
          <Button onClick={()=>{
            setWitnesses(oldArray => [...oldArray, witness]);
            setWitness({});
          }} type="primary" className="ant-btn-sm m-5"> ADD </Button> 
        </>
        :
        <div>
          <Button onClick={()=>deleteElement(data)} className='btn btn-sm' danger><DeleteFilled/></Button>
        </div>
      },
      width: "7%",
    }
  ];
  return (
    <div className="table-responsive">
        <Table
            columns={cols}
            dataSource={list.concat({key : "create"})}
            pagination={false}
            className="ant-border-space"
        />
    </div>
  )
}



const Victims = ({v, setVictims, participants, employees}) =>{

  const [list, setList] = useState([]);

  useEffect(()=>{
    setList(v);
  }, [v])
  const [victim, setVictim] = useState({
    name : "",
  });

  const handleChange = e => {
      const { name, value } = e.target;
      setVictim(prevState => ({
          ...prevState,
          [name]: value
      }));
  };

  const deleteElement = (object) =>{
    setVictims(list.filter(x => x != object))
  }

  const [selected, setSelector] = useState([0]);

  return (
    <div>
        <Row className='mb-2'>
          {
            list.map(item=><>
              <div className='m-5'>{item.name}</div> 
              <Button style={{width:"26px", height : "20px", lineHeight:"10px", paddingLeft:"5px"}}  onClick={()=>deleteElement(item)} className='m-5 ant-btn-small' danger><DeleteFilled/></Button>
            </>)
          }
        </Row>

        <Row style={{display:"flex", flexDirection:"row"}}>
          <Col sm={24} md={9} lg={9}>
            <Radio onClick={()=>{setSelector(0); setVictim("")}} checked={selected == 0}>Other Victim</Radio>
            <Radio onClick={()=>{setSelector(1); setVictim("")}} checked={selected == 1}>Participant</Radio>
            <Radio onClick={()=>{setSelector(2); setVictim("")}} checked={selected == 2}>Employee</Radio>
          </Col>
          <Col sm={24} md={15} lg={15} style={{display:"flex"}}>
          <div style={{width:"80%"}}>

            {
              selected == 0 &&
              <Input name='name' value={victim?.name} onChange={(e)=>handleChange(e)}></Input>
            }
            
            {
              selected == 1 &&
              <Select
                style={{height:"100%"}}
                  value={victim?.name}
                  showSearch
                  onChange={(value)=>{
                    setVictim({name : value});
                  }}
                  placeholder="Select Participant"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.includes(input)}
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  // }
                >
                  {
                    participants.map(item =>
                      <Option value={`${item.name} {NDIS NUMBER. ${item.ndisnumber}}`}>{item.name} {'{'}Ref No. {item.ndisnumber}{'}'}</Option>
                      )
                  }
              </Select>
            }
            
            {
              selected == 2 &&
              <Select 
                style={{height:"100%"}}
                className='m-0'
                  showSearch
                  value={victim?.name}
                  onChange={(value)=>{
                    setVictim({name : value});
                  }}
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.includes(input)}
                  // filterSort={(optionA, optionB) =>
                  //   (optionA.children || " ").toLowerCase().localeCompare((optionB.children || " ").toLowerCase())
                  // }
                >
                  {
                    employees?.map(item =>
                        <Option value={`${item.name} {Ref No. ${item.id}}`} >{item.name} {'{'}Ref No. {item.id}{'}'}</Option>
                      )
                  }
                </Select>
            }
          </div>

          <Button onClick={()=>{
            setVictims(oldArray => [...oldArray, victim]);
            setVictim("");
          }} type="primary" className="m-5" style={{height:"100%", width:"20%"}}> ADD</Button>

          </Col>


        </Row>
    </div>
  )
}