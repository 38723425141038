
import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
} from "antd";

import { PlusOutlined, ExclamationOutlined } from "@ant-design/icons";
import mastercard from "../assets/images/mastercard-logo.png";
import paypal from "../assets/images/paypal-logo-2.png";
import visa from "../assets/images/visa-logo.png";
import StripeCard from "./StripeCheckout";
import { useEffect, useState } from "react";
import axios from "axios";


const pencil = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
      className="fill-gray-7"
    ></path>
    <path
      d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
      className="fill-gray-7"
    ></path>
  </svg>,
];
const download = [
  <svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key="0"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L9 10.5858L9 3C9 2.44772 9.44771 2 10 2C10.5523 2 11 2.44771 11 3L11 10.5858L12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071L10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14C9.73478 14 9.48043 13.8946 9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289Z"
      fill="#111827"
    ></path>
  </svg>,
];

function Billing(permissions) {
  const data = [
    {
      title: "March, 01, 2021",
      description: "#MS-415646",
      amount: "$180",
    },
    {
      title: "February, 12, 2021",
      description: "#RV-126749",
      amount: "$250",
    },
    {
      title: "April, 05, 2020",
      description: "#FB-212562",
      amount: "$550",
    },
    {
      title: "June, 25, 2019",
      description: "#QW-103578",
      amount: "$400",
    },
    {
      title: "March, 03, 2019",
      description: "#AR-803481",
      amount: "$700",
    },
  ];
const [state, setState] = useState({ invoices : [], editPaymentMethod : false});

useEffect(()=>{
    getStripeSubscriptionInvoices();
    getMyCompany();
}, [permissions])

const getMyCompany = () =>{
    axios.get(process.env.REACT_APP_API_URI+"/companies/my/", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
    setState({...state, company : data.data, editPaymentMethod : data.data?.subscriptionId == null})
    }).catch(error =>{
        console.log(error)
    })
}
const getStripeSubscriptionInvoices = () =>{
    axios.get(process.env.REACT_APP_API_URI+"/subscriptions/stripe/invoices", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
    console.log(data.data);    
    setState({...state, invoices : data.data})
    }).catch(error =>{
        console.log(error)
    })
}

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={16}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">Payment Methods</h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        {(state?.company?.subscriptionId == null) && <Button onClick={()=>setState({...state, editPaymentMethod : !state?.editPaymentMethod})} type="primary">Update Payment Method</Button>}
                      </Col>
                    </Row>
                  </>
                }
              >
                {
                  (state?.editPaymentMethod === true) && 
                  <Row gutter={[24, 0]}>
                    <Col span={24} md={24}>
                      <StripeCard/>
                    </Col>
                  </Row>
                }
                
              </Card>
            </Col>
            <Col span={24} md={24} className="mb-24">
            <Card
            bordered={false}
            className="header-solid h-full ant-invoice-card"
            title={[<h6 className="font-semibold m-0">Invoices</h6>]}
            extra={[
              <Button type="primary">
                <span>VIEW ALL</span>
              </Button>,
            ]}
          >
            <List
              itemLayout="horizontal"
              className="invoice-list"
              dataSource={state?.invoices || []}
              renderItem={(item) => (
                <List.Item
                  actions={[<Button type="link" href={item?.invoice_pdf}>{download} PDF</Button>]}
                >
                  <List.Item.Meta
                    title={item.invoices}
                    description={(item.billing_reason || "").toUpperCase()}
                  />
                  <List.Item.Meta description={(item?.status || "").toUpperCase()}/>
                  <div className="amount">{(item.currency || "").toUpperCase()} {item.amount_paid}</div>
                </List.Item>
              )}
            />
          </Card>
        </Col>

          </Row>
        </Col>

        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            className="header-solid h-full ant-invoice-card"
            title={[<h6 className="font-semibold m-0">Billing Notifications</h6>]}
            extra={[
              <Button type="primary">
                <span>VIEW ALL</span>
              </Button>,
            ]}
          >
            {/* <List
              itemLayout="horizontal"
              className="invoice-list"
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  actions={[<Button type="link">{download} PDF</Button>]}
                >
                  <List.Item.Meta
                    title={item.title}
                    description={item.description}
                  />
                  <div className="amount">{item.amount}</div>
                </List.Item>
              )}
            /> */}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Billing;
