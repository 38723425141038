import { Button, Modal, Radio, Form, Input, Select, Row, Col, DatePicker, TimePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
const { TextArea } = Input; 
const { Option } = Select;

const App = ({show, setOpenCorrectiveActionModal, incidentId, action_value, refresh}) => {

  const [incident_id, setIncidentId] = useState("")
  const [status, setStatus] = useState("")
  const [recommendations, setRecommendations] = useState("")
  const [proposed_corrective_actions, setProposedCorrectiveActioons] = useState("")
  const [outcome_evalutions, setOutcomeEvaluations] = useState("")
  const [norminated_completion_date, setNorminatedCompletionDate] = useState("")
  const [completion_date, setCompletionDate] = useState("")
  const [start_date, setStartDate] = useState("")
  const [priority, setPriority] = useState("")
  const [employees, setEmployees] = useState([])
  const [person_responsible, setPersonResponsible] = useState("")
  const [action, setAction] = useState(action_value)


  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URI+"/employees/less", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(res =>{
      setEmployees(res.data.res);
    }).catch(error =>{
      console.log(error)
    })
    axios.get(process.env.REACT_APP_API_URI+`/correctiveactions/${incidentId}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(res =>{
      if(res.data.res != null){
        setIncidentId(res.data.res?.incident_id)
        setStatus(res.data.res?.status)
        setRecommendations(res.data.res?.recommendations)
        setProposedCorrectiveActioons(res.data.res?.proposed_corrective_actions)
        setOutcomeEvaluations(res.data.res?.outcome_evalutions)
        setNorminatedCompletionDate(moment(res.data.res?.norminated_completion_date))
        setCompletionDate(moment(res.data.res?.completion_date))
        setStartDate(moment(res.data.res?.start_date))
        setPriority(res.data.res?.priority)
        setPersonResponsible(res.data.res?.person_responsible)
       setAction(`edit`)
      }else{
          clear()
          setAction(`create`)
      }

    }).catch(error =>{
      console.log(error)
    })        
  }, [show]);

  const clear = () =>{
    setStatus("")
    setRecommendations("")
    setProposedCorrectiveActioons("")
    setOutcomeEvaluations("")
    setNorminatedCompletionDate("")
    setCompletionDate("")
    setStartDate("")
    setPriority("")
    setPersonResponsible("")
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const create = (event) =>{
    const data = {
      incident_id : incidentId,
      person_responsible : person_responsible,
      status : status,
      recommendations : recommendations,
      proposed_corrective_actions : proposed_corrective_actions,
    //   outcome_evalutions : outcome_evalutions,
      norminated_completion_date : norminated_completion_date,
      start_date : start_date,
      priority : priority, 
    } 
  axios.post(process.env.REACT_APP_API_URI+"/correctiveactions/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
    console.log(res)
    setLoading(false)
    setOpenCorrectiveActionModal(false)
  }).catch(error=>{
    if (error.response) {
      setLoading(false)
      // Server Error Massage
      console.log(error.response.data?.res);
    } else if (error.request) {
      // Request was made but no response
      console.log(error.request);
    } else {
      // Network Error
      console.log('Error', error.message);
    }
  })
}

const edit = (event) =>{
    const object = {
        person_responsible : person_responsible,
        incident_id : incident_id,
        status : status,
        recommendations : recommendations,
        proposed_corrective_actions : proposed_corrective_actions,
        outcome_evalutions : outcome_evalutions,
        norminated_completion_date : norminated_completion_date,
        start_date : start_date,
        priority : priority, 
      } 
    axios.put(process.env.REACT_APP_API_URI+`/correctiveactions/?incident_id=${incidentId}`, object , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
        refresh()
        console.log(res)
        setLoading(false)
        setOpenCorrectiveActionModal(false)
        }).catch(error=>{
        console.log(error)
        if (error.response) {
            setLoading(false)
            // Server Error Massage
            console.log(error?.response);
        } else if (error.request) {
            // Request was made but no response
            console.log(error?.request);
        } else {
            // Network Error
            console.log('Error', error?.message);
        }
        })
}

  const [loading, setLoading] = useState(false)

  return (
    <>
      <Modal 
      title={`CORRECTIVE ACTION FORM INCIDENT #${incidentId}`}
      visible={isModalVisible} 
      onOk={()=>setOpenCorrectiveActionModal(false)} 
      onCancel={()=>setOpenCorrectiveActionModal(false)}
      footer={[
        <Button key="submit" type='danger' style={{color:"red", backgroundColor:"white"}}  onClick={()=>{setOpenCorrectiveActionModal(false)}}>
            Cancel
        </Button>,
        action != `opening`?
          <Button key="submit" type="primary" loading={loading} onClick={()=>{action==`create`?create() : edit() ;setLoading(true)}}>
            {action == `create`?`Submit`:`Save`}
          </Button>
          :
          <Button key="submit" type=""  onClick={()=>{setOpenCorrectiveActionModal(false)}}>
            Download PDF
        </Button>,
      ]}
      >
        <Form
        layout="vertical"
        className="row-col"
        >
        <Form.Item label="Choose person responsible for follow ups"> 
            <Select
                showSearch
                value={person_responsible}
                onChange={(value)=>setPersonResponsible(value)}
                style={{
                  // width: 200,
                }}
                placeholder="Report the Incident to..."
                optionFilterProp="children"
                filterOption={(input, option) => option.children.includes(input)}
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {
                  employees.map(item =>
                    <Option value={item.id}>{item.name}</Option>
                    )
                }
              </Select>
        </Form.Item>
        <Form.Item >
            <Row className='m-0'>
                <Col  xs={11} sm={11} md={11} lg={11} className='m-5'>
                    <Form.Item label="Corrective Action Start Date ">
                        <DatePicker style={{width : "100%"}}  value={start_date}  onChange={(value)=>setStartDate(value)} name = "date" placeholder="Corrective Action Start" />
                    </Form.Item>
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} className='m-5'>
                    <Form.Item label="Norminated Completion Date ">
                        <DatePicker style={{width : "100%"}}  value={norminated_completion_date}  onChange={(value)=>setNorminatedCompletionDate(value)} name = "date" placeholder="Norminated Completition Date" />
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item>
        <Form.Item label = "Proposed Corrective Actions">
          <TextArea  value={proposed_corrective_actions}  autoSize={{minRows: 3, maxRows: 8}} placeholder="Enter proposed corrective actions..." allowClear onChange={(event)=>setProposedCorrectiveActioons(event.target.value)}/>
        </Form.Item>
        <Form.Item label="Recomentations on Proposed Corrective Action">
          <TextArea  value={recommendations} autoSize={{minRows: 3, maxRows: 8}} placeholder="Draft recomentations on proposed corrective actions" allowClear onChange={(event)=>setRecommendations(event.target.value)}/>
        </Form.Item>
        <Form.Item label="Corrective Action Priority:">
          <Row>
            <Radio name = "priority" checked={priority=="Insignficant"} onChange={()=>setPriority(`Insignficant`)}>Insignficant</Radio>
            <Radio name = "priority" checked={priority=="Moderate"}  onChange={()=>setPriority(`Moderate`)}>Moderate</Radio>
            <Radio name = "priority" checked={priority=="Significant"}  onChange={()=>setPriority(`Significant`)}>Significant</Radio>
            <Radio name = "priority" checked={priority=="Critical"}  onChange={()=>setPriority(`Critical`)}>Critical</Radio>
          </Row>
        </Form.Item>

        {
            action =="edit"?
            <>
                <Form.Item label='Outcome Evalutions:'>
                    <TextArea  value={outcome_evalutions}  autoSize={{minRows: 3, maxRows: 8}} placeholder="Enter  corrective action's outcome evaluations..." allowClear onChange={(event)=>setOutcomeEvaluations(event.target.value)}/>
                </Form.Item>
                <Form.Item label="Corrective Action Completion Status:"> 
                    <Row>
                        <Radio name = "status" checked={status=="waiting"} onChange={()=>setStatus(`waiting`)}>waiting</Radio>
                        <Radio name = "status" checked={status=="under way"}  onChange={()=>setStatus(`under way`)}>Under way</Radio>
                        <Radio name = "status" checked={status=="completed"}  onChange={()=>setStatus(`completed`)}>completed</Radio>
                        <Radio name = "status" checked={status=="terminated"}  onChange={()=>setStatus(`terminated`)}>terminated</Radio>
                    </Row>
                </Form.Item>
            </>
            :
            ""
        }
        </Form>
      </Modal>
    </>
  );
};

export default App;
